import React from 'react';
import PropTypes from 'prop-types';
import Poll from '@components/DynamicComponents/Poll';
import FourTile from '@components/DynamicComponents/FourTile';
import { HOMEPAGE_COMPONENT_TYPES } from '@constants/index';
import s from './FlexibleContainer.module.scss';
import FlexibleImageCTA from './FlexibleImageCTA';

const propTypes = {
  contentSlots: PropTypes.arrayOf(
    PropTypes.shape({
      contentTypeId: PropTypes.string,
      contentSlots: PropTypes.array,
      answer: PropTypes.array,
    }),
  ).isRequired,
  header: PropTypes.string,
  subheader: PropTypes.string,
  analyticsParams: PropTypes.shape({
    verticalIndex: PropTypes.number.isRequired,
  }),
};

const defaultProps = {
  header: '',
  subheader: '',
  analyticsParams: {},
};

const FlexibleContainer = ({ contentSlots, header, subheader, analyticsParams }) => {
  // helper to get the number of clickable items in the first content slot
  // to offset the horizontal index in second content slot
  const getHorizontalIndexOffset = () => {
    const firstContent = contentSlots[0];
    switch (firstContent.contentTypeId) {
      case HOMEPAGE_COMPONENT_TYPES.MULTI_TILE_PROMO: {
        // max 4 items are shown, don't offset by more that aren't shown
        return Math.min(firstContent?.contentSlots?.length, 4) || 0;
      }
      case HOMEPAGE_COMPONENT_TYPES.POLL: {
        // max 4 answers are shown, don't offset by more that aren't shown
        return Math.min(firstContent?.answer?.length, 4) || 0;
      }
      case HOMEPAGE_COMPONENT_TYPES.FLEXIBLE_IMAGE_CTA: {
        return 1;
      }
      /* istanbul ignore next */
      default:
        return 0;
    }
  };

  const renderComponent = (subContent, index) => {
    const subAnalyticsParams = {
      ...analyticsParams,
      horizontalIndexOffset: index > 0 ? getHorizontalIndexOffset() : 0,
    };

    switch (subContent.contentTypeId) {
      case HOMEPAGE_COMPONENT_TYPES.MULTI_TILE_PROMO: {
        return <FourTile content={subContent.contentSlots} analyticsParams={subAnalyticsParams} />;
      }
      case HOMEPAGE_COMPONENT_TYPES.POLL: {
        return <Poll poll={subContent} analyticsParams={subAnalyticsParams} />;
      }
      case HOMEPAGE_COMPONENT_TYPES.FLEXIBLE_IMAGE_CTA: {
        return <FlexibleImageCTA content={subContent} analyticsParams={subAnalyticsParams} />;
      }
      /* istanbul ignore next */
      default:
        return null;
    }
  };

  return (
    <div className={s.root}>
      {(header || subheader) && (
        <div className={s.heading}>
          {header && <div className={s.header}>{header}</div>}
          {subheader && <div className={s.subHeader}>{subheader}</div>}
        </div>
      )}
      <div className={s.twoColumn}>
        {contentSlots.map((subContent, index) => {
          return (
            <div
              key={subContent.contentfulId}
              className={[
                /* istanbul ignore next */
                subContent?.contentTypeId ? s[subContent.contentTypeId] : '',
              ].join(' ')}
            >
              {renderComponent(subContent, index)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

FlexibleContainer.propTypes = propTypes;
FlexibleContainer.defaultProps = defaultProps;

export default FlexibleContainer;
