/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import SharedLibs from 'wizarding-world-web-shared';
import RenderAnimation from '@components/RenderAnimation';
import Bugsnag from '@bugsnag/js';
import { POLL_ANSWER_LOCALSTORAGE_KEY } from '@constants/index';
import { useInView } from 'react-intersection-observer';
import { trackClickEvent } from '@utils/analytics';
import { POLL_RESULT, SUBMIT_POLL, AGGREGATED_POLL_RESULT } from '@utils/graphQueries';
import { useUserContext } from '@contexts/user-context';
import animationData from '../../../animations/scroll_indicator.json';
import s from './Poll.module.scss';

const propTypes = {
  poll: PropTypes.shape({
    contentfulId: PropTypes.string.isRequired,
    displayTitle: PropTypes.string.isRequired,
    answer: PropTypes.array.isRequired,
  }).isRequired,
  analyticsParams: PropTypes.shape({
    verticalIndex: PropTypes.number.isRequired,
    horizontalIndexOffset: PropTypes.number,
  }),
};

const defaultProps = {
  analyticsParams: {},
};

const Poll = ({ poll, analyticsParams }) => {
  const { api: wizardingWorldApi } = SharedLibs.services;
  const { profile, loadingProfile, isLoggedIn } = useUserContext();
  const { displayTitle, answer, contentfulId } = poll;
  // Show max 4 poll answers
  const pollAnswers = answer.slice(0, 4);
  const [showResults, setShowResults] = useState(false);
  const [results, setResults] = useState(null);
  const [totalCount, setTotalCount] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(true);
  const { verticalIndex, horizontalIndexOffset } = analyticsParams;
  const pollLocalStorageKey = `${POLL_ANSWER_LOCALSTORAGE_KEY}-${contentfulId}`;
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const getPercentage = (count) => {
    return ((count / totalCount) * 100).toFixed(0);
  };

  const getAggregatedResult = async () => {
    try {
      const { data } = await wizardingWorldApi().query({
        query: AGGREGATED_POLL_RESULT,
        variables: {
          pollId: contentfulId,
        },
      });
      const total = data?.aggregatedPollResult?.answers?.reduce((sum, a) => sum + a.count, 0);
      const result = pollAnswers.map((a) => {
        const answerResult = data?.aggregatedPollResult?.answers.find((r) => {
          return r.answer === a;
        });
        return {
          answer: a,
          count: answerResult?.count || 0,
        };
      });
      setResults(result);
      setTotalCount(total);
      setShowResults(true);
      setLoading(false);
    } catch (e) {
      /* istanbul ignore next */
      Bugsnag.notify(e);
    }
  };

  const submitPoll = async (option) => {
    const input = {
      pollId: contentfulId,
      pollAnswer: option,
      hogwartsHouse: profile?.hogwartsHouse || 'unsorted',
    };
    try {
      await wizardingWorldApi().mutate({
        mutation: SUBMIT_POLL,
        variables: {
          input,
        },
      });
    } catch (e) {
      /* istanbul ignore next */
      Bugsnag.notify(e);
    }
  };

  const selectAnswer = async (option, index) => {
    trackClickEvent(
      {
        destination_url: window.location.pathname,
        label: 'Poll Submitted',
        location: 'Poll',
        vertical_index: verticalIndex,
        horizontal_index: index + horizontalIndexOffset,
        content_name: displayTitle,
      },
      poll,
    );
    if (isLoggedIn) {
      await submitPoll(option);
      setSelectedOption(option);
      getAggregatedResult();
    } else {
      // For anon users, store current poll answer in localStorage and get aggregated result
      localStorage.setItem(pollLocalStorageKey, option);
      setSelectedOption(option);
      getAggregatedResult();
    }
  };

  const checkPollStatus = async () => {
    try {
      const { data } = await wizardingWorldApi().query({
        query: POLL_RESULT,
        variables: {
          pollId: contentfulId,
        },
      });
      // If current poll is answered, get aggregated result
      if (data.pollResult) {
        setSelectedOption(data.pollResult.pollAnswer);
        getAggregatedResult();
      } else {
        setLoading(false);
      }
    } catch (e) {
      /* istanbul ignore next */
      Bugsnag.notify(e);
    }
  };

  useEffect(() => {
    if (loadingProfile) {
      return;
    }
    if (isLoggedIn) {
      checkPollStatus();
    } else {
      const pollAnswer = localStorage.getItem(pollLocalStorageKey);
      // If current poll is answered, get aggregated result
      if (pollAnswer) {
        setSelectedOption(pollAnswer);
        getAggregatedResult();
      } else {
        setLoading(false);
      }
    }
  }, [loadingProfile, isLoggedIn]);

  return (
    <div className={cx(s.root, inView ? s.isInView : '')} ref={ref}>
      <div className={s.textContainer}>
        <div className={s.header}>Poll</div>
        {loading && (
          <div data-testid="loading-skeleton">
            <RenderAnimation
              animation="scroll-indicator"
              animationData={animationData}
              ariaLabel="Animated scroll indicator"
              className={s.scrollIndicator}
            />
            <div className={s.answerSkeleton}>
              {pollAnswers.map((option, index) => (
                <div key={index} className={s.answerItem} />
              ))}
            </div>
          </div>
        )}
        {!loading && <div className={s.question}>{displayTitle}</div>}
        {!loading && !showResults && (
          <div className={s.options} data-testid="options">
            {pollAnswers.map((option, index) => (
              <button
                type="button"
                key={option}
                className={s.option}
                onClick={() => {
                  selectAnswer(option, index);
                }}
              >
                {option}
              </button>
            ))}
          </div>
        )}
        {!loading && showResults && (
          <div className={s.results} data-testid="results">
            {results.map((result, index) => (
              <div
                className={cx(s.option, result.answer === selectedOption ? s.selected : '')}
                key={index}
              >
                <div className={s.optionTextContainer}>
                  <div>{result.answer}</div>
                  <div className={s.percentage}>{`${getPercentage(result.count)}%`}</div>
                </div>
                <div
                  className={s.progressBarContainer}
                  style={{ '--percentage': `${getPercentage(result.count)}%` }}
                >
                  <div className={s.progress} />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
Poll.propTypes = propTypes;
Poll.defaultProps = defaultProps;
export default Poll;
